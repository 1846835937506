.favorite-products-product{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    -moz-box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    width: 20%;
    max-width: 250px;
    min-width: 250px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    transition-property: all;
    transition-duration: 0.7s;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.7s;
    -o-transition-property: all;
    -o-transition-duration: 0.7s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.7s;
}

.favorite-products-product:hover{
    border: 1px solid #394854;
    transition-property: all;
    transition-duration: 0.7s;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.7s;
    -o-transition-property: all;
    -o-transition-duration: 0.7s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.7s;
}

.favorite-products-product-name{
    font-size: 20px;
    line-height: 1.1;
    height: 2em;
    text-align: center;
    text-transform: capitalize;
    font-weight: bolder;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
}

.favorite-products-product-brand{
    width: 80%;
}

.favorite-products-product-brand span{
    font-weight: 800;
    font-size: 12px;
    border-radius: 4px;
    color: white;
    background-color: #03a691;
}


.favorite-products-product-description{
    width: 80%;
    height: 60px;
}

.favorite-products-product-img{
    width: 150px;
    height: 150px;
    filter: drop-shadow(1px 1px 1px #222);
    -webkit-filter: drop-shadow(1px 1px 1px #222);
    -moz-filter: drop-shadow(1px 1px 1px #222);
}