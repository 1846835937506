.little-box{
    border: 3px solid white;
    color: #394854;
    background-color: white;
    font-weight: bolder;
    width: 55px;
    font-size: 0.75em;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: all 0.7s;
    -ms-transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
}

.little-box:hover{
    background-color: #394854;
    color: white;
    transition: all 0.7s;
    -ms-transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
}