.search-engine-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.search-engine{
    width: 50%;
}

@media(max-width:780px){
    .search-engine{
        width: 90%;
    }
}

.search-engine input {
    width: 100%;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    height: 46px;
    background-image:url('https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSS-C0jtf2YS_FGbWcuMrrRIv13srlP5dN7Hw&usqp=CAU');
    background-repeat:no-repeat;
    background-position:left top;
    background-size: 40px 40px;
    background-position-x: 15px;
    padding-left: 65px;
}

.search-engine input:focus{
    outline: none;
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    -moz-box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
}

.search-engine input:hover{
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    -moz-box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
}

.search-engine-result{
    width: 50%;
    display: flex;
    align-items: center;
}

.search-engine-result img{
    width: 20px;
    height: 20px;
}