.contact-data-container{
    display: flex;
    align-items: center;
    background-color: #394854;
    color: white;
}

.contact-data{
    width: 50%;
    display: flex;
    align-items: center;
}

.contact-data-map{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.contact-data-map iframe{
    width: 100%;
    height: 500px;
}

.contact-data-info{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-data-additional-info{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.contact-data-item{
    display: flex; 
    align-items: center;
    margin: 30px 0;
}

.contact-data-item ul{
    list-style: none;
}

.contact-data-item ul li{
    list-style-position: inside;
    margin: 5px;
}

.contact-data-item ul li a{
    color: #2ECCFA;
    text-decoration: none;
    cursor: pointer;
}

.contact-data-item-left img{
    height: 30px;
    width: 30px;
}

.contact-data-item-right{
    margin-left: 20px;;
}

.contact-data-item-addition-item{
    margin: 30px 0;
}

.contact-data-item-addition-item h2{
    line-height: 1.5em;
}

.contact-data-item-addition-item p{
    text-indent: 15px;
    line-height: 1.5em;
}

@media(max-width: 1600px){

    .contact-data-container{
        flex-direction: column;
    }

    .contact-data{
        width: 100%;
    }
    
    .contact-data-map{
        width: 100%;
    }
}

@media(max-width: 1000px){

    .contact-data{
        flex-direction: column;
    }

    .contact-data-info{
        width: 100%;
    }

    .contact-data-additional-info{
        width: 100%;
    }
}