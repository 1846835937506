.page-not-found{
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 100%;
    align-items: center;
}

.page-not-found-logo img{
    width: 400px;
}

.page-not-found-report-error{
    color: red;
}

.page-not-found-report-error a{
    color: red;
    font-weight: bold;
    text-decoration: underline;
}