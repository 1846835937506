.product-presentation-container{
    display: flex;
    justify-content: space-around;
    border: 1px solid #ccc;
}

.product-presentation-image{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
}

.product-presentation-description{
    width: 55%;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;
    background-color: #394854;
    color: white;

}

.product-presentation-description-title{
    font-size: 1.8em;
    font-weight: bolder;
}

.product-presentation-description-description{
    font-size: 1.2em;
    line-height: 1.2em;
    width: 100%;
}

.product-presentation-description-description p{
    width: 75%;
}

.product-presentation-image img{
    height: 300px;
    width: 300px;
    filter: drop-shadow(1px 1px 1px #222);
    -webkit-filter: drop-shadow(1px 1px 1px #222);
    -moz-filter: drop-shadow(1px 1px 1px #222);
}

.product-presentation-description-variety ul{
    list-style: none;
    display: flex;
    flex-direction: column;
}   

.product-presentation-description-variety ul li{
    margin: 0.2em 0;
    display: flex;
    align-items: center;
}

.product-presentation-description-variety ul li img{
    width: 15px;
}

.product-presentation-description-price{
	color: white;
	padding: 5px;
}

@media(max-width: 900px){
    .product-presentation-container{
        flex-direction: column;
    }

    .product-presentation-image{
        width: 100%;
    }

    .product-presentation-description{
        width: 100%;
    }
}