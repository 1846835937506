.button-lavoc-primary{
    border-radius: 4px;
    background-color: #394854;
    color: white;
    border: 1px solid white;
    text-decoration: none;
    display: block;
    font-weight: 600;
    width: fit-content;
    transition-property: all;
    transition-duration: 0.7s;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.7s;
    -o-transition-property: all;
    -o-transition-duration: 0.7s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.7s;
}

.button-lavoc-primary:hover{
    background-color: white;
    color: #394854;
    border: 1px solid #394854;
    transition-property: all;
    transition-duration: 0.7s;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.7s;
    -o-transition-property: all;
    -o-transition-duration: 0.7s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.7s;
}

.button-lavoc-secondary{
    border-radius: 4px;
    background-color: white;
    color: #394854;
    border: 1px solid transparent;
    text-decoration: none;
    display: block;
    font-weight: 600;
    width: fit-content;
    transition-property: all;
    transition-duration: 0.7s;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.7s;
    -o-transition-property: all;
    -o-transition-duration: 0.7s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.7s;
}

.button-lavoc-secondary:hover{
    background-color: #394854;
    color: white;
    border: 1px solid white;
    transition-property: all;
    transition-duration: 0.7s;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.7s;
    -o-transition-property: all;
    -o-transition-duration: 0.7s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.7s;
}