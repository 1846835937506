#first-view{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: flex-start;
    background: url("./images/worker.jpg") no-repeat center center;
    -webkit-backgound-size: cover;
}

.first-view-description{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.first-view-description img{
    position: absolute;
    width: 1200px;
    height: 1200px;
    align-self: auto;
    top: 50%;
    left: 50%;
    opacity: 0.1;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.first-view-description-title{
    font-size: 1.8em;
    text-align: center;
    width: 100%;
}

.first-view-description-subtitle{
    width: 75%;
    font-size: 1.4em;
    text-align: center;
    line-height: 1.2em;
}

@media(max-width: 1200px){
    #first-view{
        background: none;
        background-color: #394854;
        color: white;
    }

    .first-view-description{
        width: 100%;
    }
}