.carousel-container{
    width: 90%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
}

@media(max-width: 950px){
    .carousel-container{
        width: 70%;
    }
}

.carousel-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.carousel-absolute-right-arrow, .carousel-absolute-left-arrow{
    position: absolute;
    top: 49%;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    box-shadow: 0 4px 8px rgba(68,68,68,0.2);
}

.carousel-absolute-right-arrow:hover, .carousel-absolute-left-arrow:hover {
    box-shadow: 0 12px 20px rgba(68,68,68,0.2);
}

.carousel-absolute-right-arrow img, .carousel-absolute-left-arrow img{
    height: 20px;
    width: 20px;
}
.carousel-absolute-left-arrow{
    left: -45px;
}

.carousel-absolute-right-arrow{
    right: -45px;
}

.carousel-title h3 a{
    text-decoration: none;
    color: #1259c3;
    font-size: 0.7em;
    margin-left: 25px;
}

.carousel-title h3 {
    font-size: x-large;
    color: black;
    font-weight: 100;
}

.carousel-title h3 a:hover{
    text-decoration: underline;
    color: blue;
    transition-duration: 0.7s;
    cursor: pointer;
    -ms-transition-duration: 0.7s;
    -webkit-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    -moz-transition-duration: 0.7s;
}