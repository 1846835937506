.how-business-work-step{
    display: flex;
    justify-content: center;
    width: 20%;
    max-width: 400px;
    min-width: 300px;
    align-items: center;
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    -moz-box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    box-shadow: 0 0 10px 2px rgba(0,0,0,.08);
    border: 1px solid #DADADA;
    border-radius: 10px;
    transition-property: all;
    transition-duration: 0.7s;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.7s;
    -o-transition-property: all;
    -o-transition-duration: 0.7s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.7s;
}

.how-business-work-step:hover{
    border: 1px solid #394854;
    transform: scale(1.05);
    /*-webkit-transform: scale(0.7);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);*/
    -ms-transition-property: all;
    transition-duration: 0.7s;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.7s;
    -o-transition-property: all;
    -o-transition-duration: 0.7s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.7s;
}

.how-business-work-step-item{
    margin: 0px 10px;
    font-weight: bolder;

}

.how-business-work-step img{
    width: 50px;
    height: 50px;
}

.how-business-work-step-item h5 {
    color: #8e8e8e;
}