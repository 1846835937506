.navbar{
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #394854;
}

.navbar .logo-navbar{
    width: 35px;
    height: 35px;
}

.logo-and-name{
    display: flex;
    align-items: center;
}

.logo-and-name div{
    padding: 5px 5px;
}

.nav-items ul li{
    display: inline;
}

.nav-items ul li a{
    padding: 15px 20px;
    color: black;
    text-decoration: none;
}

.logo-and-name a{
    text-decoration: none;
    color: black;
}

.nav-items ul li a:hover{
    color: white;
    background-color: #394854;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    -moz-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
}