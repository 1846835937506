.favorite-products-category{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.favorite-products-category-title h3 a{
    text-decoration: none;
    color: #1259c3;
    font-size: 0.7em;
    margin-left: 25px;
}

.favorite-products-category-title h3 {
    font-size: x-large;
    color: black;
    font-weight: 100;
}

.favorite-products-category-title h3 a:hover{
    text-decoration: underline;
    color: blue;
    transition-duration: 0.7s;
    cursor: pointer;
    -ms-transition-duration: 0.7s;
    -webkit-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    -moz-transition-duration: 0.7s;
}